// import dependencies
import Amplify, {API} from "aws-amplify";
import awsConfig from "./src/aws-exports";

// import styles and assets
import "./src/styles/bootstrap.min.css";
import "./src/styles/reset.scss";

// amplify configuration
Amplify.configure(awsConfig);
API.configure();

// set sanity client
// const sanityClient = require('@sanity/client')
// const client = sanityClient({
//   projectId: process.env.SANITY_PROJECT_ID,
//   dataset: process.env.SANITY_DATASET,
//   apiVersion: '2021-03-25', // use a UTC date string
//   token: process.env.SANITY_TOKEN,
//   useCdn: true, // `false` if you want to ensure fresh data
// })