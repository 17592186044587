// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-apoderados-js": () => import("./../../../src/pages/apoderados.js" /* webpackChunkName: "component---src-pages-apoderados-js" */),
  "component---src-pages-docentes-js": () => import("./../../../src/pages/docentes.js" /* webpackChunkName: "component---src-pages-docentes-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-ninas-emulador-js": () => import("./../../../src/pages/ninas-emulador.js" /* webpackChunkName: "component---src-pages-ninas-emulador-js" */),
  "component---src-pages-ninas-js": () => import("./../../../src/pages/ninas.js" /* webpackChunkName: "component---src-pages-ninas-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-corfo-js": () => import("./../../../src/templates/corfo.js" /* webpackChunkName: "component---src-templates-corfo-js" */),
  "component---src-templates-inspiracion-js": () => import("./../../../src/templates/inspiracion.js" /* webpackChunkName: "component---src-templates-inspiracion-js" */),
  "component---src-templates-mercado-laboral-js": () => import("./../../../src/templates/mercado-laboral.js" /* webpackChunkName: "component---src-templates-mercado-laboral-js" */),
  "component---src-templates-mineduc-js": () => import("./../../../src/templates/mineduc.js" /* webpackChunkName: "component---src-templates-mineduc-js" */),
  "component---src-templates-orientacion-vocacional-js": () => import("./../../../src/templates/orientacion-vocacional.js" /* webpackChunkName: "component---src-templates-orientacion-vocacional-js" */),
  "component---src-templates-perfiles-js": () => import("./../../../src/templates/perfiles.js" /* webpackChunkName: "component---src-templates-perfiles-js" */)
}

